html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b1963;
  min-height: 90%;
  width: 100%;
  margin: 0px;
  overflow-x: wrap;
  color: rgb(255, 255, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: underline;
}

.bg-layer-1 {
  background-color: #0b1963;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
  animation-name: bg-transition;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}

.skill-wrapper {
  padding-top: 1rem;
}
.skill-title {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
}
.skillsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 0.8rem;
  padding: 10px;
}

.skillsImage {
  max-height: 50px;
}

.skillsCard {
  display: flex;
  height: 100px;
  width: 100px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


@keyframes bg-transition {
  0% {
    background-color: #090177;
  }
  50% {
    background-color: #091863;
  }
  100% {
    background-color: #050945;
  }
}

.bg-layer-2 {
  background-color: #001061;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: -2;
}

.main {
  padding-top: 2rem;
  color: rgb(255, 255, 255);
  text-align: center;
  margin: 5px;
}

.title {
  font-size: 3rem;
  height: fit-content;
  justify-content: center;
  margin: 10px;
  padding-top: 3rem;
}

.subtitle {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.image-container {
  display: flex;
  justify-content: center;
}

.profile-pic {
  border-radius: 50%;
  height: 10vw;
  width: 10vw;
  justify-content: center;
}

.links {
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.links > span {
  margin: 20px;
}


@media only screen and (max-device-width: 768px) {
  html {
    font-size: 80px;
  }
  .profile-pic {
    height: 30vw;
    width: 30vw;
  }
  .skillsContainer {
    flex-direction: column;
    align-items: center;
  }
  .projectsCard {
    flex-direction: column;
  }
  .skillsCard {
    font-size: 0.7rem;
    padding: 0px 10px 0px 10px;
    height: 250px;
    width: 250px;
  }
  .skillsImage {
    max-height: 100px;
  }
  .projectsImage {
    height: 250px;
  }
  .links > span {
    margin: 0;
    padding: o;
  }
  .skill-title {
    font-size: 1rem;
  }
  .skillsContainer {
    font-size: o.5rem;
  }
  
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 80px;
  }
  .title {
    padding-top: 0.5rem;
    font-size: 0.4rem;
  }
  .subtitle {
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-pic {
    height: 30vw;
    width: 30vw;
  }
  .skillsContainer {
    flex-direction: column;
    align-items: center;
  }
  .skillsCard {
    font-size: 0.2rem;
    padding: 0px 10px 0px 10px;
    height: 150px;
    width: 150px;
  }
  .skillsImage {
    max-height: 50px;
  }
  .links {
    font-size: small;
  }
  .skill-title {
    font-size: 0.5rem;
  }
  .skillsContainer {
    font-size: 0.2rem;
  }
  
}